// Libraries
import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

// Styles
import { DividerContainer } from './divider'

// Components
import Button from '../button'

// Helpers
import ImgUtil from '../../../utils/img.utils'
import options from '../../../utils/richText.utils'
import formatInlineCode from '../../../utils/shortTextFormatting'
import { internalUrl, internalLinkHandler } from '../../../utils/link.utils' 
import WrapperImage from '../wrapperImage'

const Divider = (props: any) => {
  if (props.data) {
    const dividerHeading = props.data.headline ? props.data.headline : ''

    const image =
      props.data.image &&
      props.data.image.image &&
      props.data.image.image.file &&
      props.data.image.image.file.url
        ? props.data.image.image.file.url
        : ''

    const imageAlt =
      props.data.image && props.data.image.altText
        ? props.data.image.altText
        : ''

    const subheadline = props.data.subheadline ? props.data.subheadline : ''

    const ctaText =
      props.data.dividerCta && props.data.dividerCta.text
        ? props.data.dividerCta.text
        : ''


    let cta: { externalUrl: string; text: string; internalLink?: internalUrl} | undefined;

    if (props.data.dividerCta) cta = props.data.dividerCta 
    
    
    const theme = props.data.theme ? props.data.theme : 'Light'
    const bottomPadding = props.data.bottomPadding
    ? props.data.bottomPadding
    : 'Large'
    
    const topPadding = props.data.topPadding ? props.data.topPadding : 'None'

    return (
      <DividerContainer
        componentTheme={theme}
        bottomPadding={bottomPadding}
        topPadding={topPadding}
        data-cy="Divider"
      >
        <div className="Divider__Inner">
          <div className="Divider__Image-Container">
            <WrapperImage
              className="Divider__Image"
              loading="lazy"
              alt={imageAlt}
              src={ImgUtil.getResizedImageUrl(image, '')}
              srcSet={ImgUtil.getSrcSet(image)}
              width={1440}
              height={540}
              threshold={650}
            />
          </div>
          <div className="Divider__Text">
            <div className="Divider__Text-Inner">
              <h3 className="Divider__Heading">
                {formatInlineCode(dividerHeading)}
              </h3>
              {subheadline && (
                <p className="Divider__Subheading LeadXS">
                  {renderRichText(subheadline, options)}
                </p>
              )}
              {ctaText && cta && (
                <Button
                  icon={true}
                  href={
                    cta.internalLink
                      ? internalLinkHandler(cta.internalLink)
                      : cta.externalUrl
                  }
                  text={ctaText}
                  type="text-link"
                  size="small"
                  theme="light"
                />
              )}
            </div>
          </div>
        </div>
      </DividerContainer>
    )
  } else {
    return null
  }
}

export default Divider
