import React from 'react'
import * as styles from './style'
import GlobalContainer from '../globalContainer'
import DottedModal from '../../global/dottedModal'
import Button from '../../global/button'
import VideoPlayer from '../videoPlayer'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Typography from '../typography'

// Helpers
import options from '../../../utils/richText.utils'
import formatInlineCode from '../../../utils/shortTextFormatting'
import { internalUrl, internalLinkHandler } from '../../../utils/link.utils'
import ImgUtil from '../../../utils/img.utils'

import { ModuleNames } from '../../../constants/componentNames.constants'
import WrapperImage from '../wrapperImage'

const MediaText = (props: any) => {
  if (props.data) {
    const [open, setOpen] = React.useState(false)
    const handleClose = () => {
      setOpen(false)
    }
    const handleOpen = () => {
      setOpen(true)
    }

    const type = (mediaStyle: string) => {
      if (mediaStyle === 'Video') return 'VIDEO'
      else if (mediaStyle === 'Photo') return '16X9'
      else return 'NONE'
    }

    let headline: string = '',
      body: any,
      cta:
        | { externalUrl: string; text: string; internalLink?: internalUrl }
        | undefined,
      imageAlignment: string = 'left',
      asset: string = '',
      videoId: string = '',
      assetAltText: string = '',
      theme: string = 'Light',
      topPadding: string = 'None',
      bottomPadding: string = 'Large',
      animation: boolean = false,
      mediaStyle: string = 'Photo',
      animationMP4: boolean = false,
      assetTypename: string = '',
      start: string = '',
      mediaAsset: boolean = props.mediaAsset

    if (props.data.assetTextTheme) theme = props.data.assetTextTheme
    if (props.data.imageAlignment) imageAlignment = props.data.imageAlignment
    if (props.data.mediaStyle) mediaStyle = props.data.mediaStyle
    if (props.data.asset && props.data.asset.__typename) {
      assetTypename = props.data.asset.__typename
    }

    if (mediaAsset) {
      if (props.data?.asset?.__typename === ModuleNames.WRAPPER_VIDEO) {
        mediaStyle = 'Video'
      }
      theme = 'Grey'
    }

    if (props.data.asset) {
      if (
        props.data.asset &&
        props.data.asset.image &&
        props.data.asset.image.file &&
        props.data.asset.image.file.url
      ) {
        asset = props.data.asset.image.file.url
      }
      if (props.data.asset && props.data.asset.altText) {
        assetAltText = props.data.asset.altText
      }
      if (assetTypename && assetTypename === 'ContentfulWrapperVideo') {
        if (props.data.asset.youtubeId) videoId = props.data.asset.youtubeId
        else if (props.data.asset.vimeoId) videoId = props.data.asset.vimeoId
        if (
          props.data.asset.animationFile &&
          props.data.asset.animationFile.file &&
          props.data.asset.animationFile.file.url &&
          mediaStyle === 'Animation'
        ) {
          asset = props.data.asset.animationFile.file.url
          let last = asset.lastIndexOf('.')
          if (asset.substring(last).includes('.mp4')) animationMP4 = true
          animation = true
        }
        if (props.data.asset.start) start = props.data.asset.start
      }
    }

    if (
      (mediaStyle === 'Video' && !videoId) ||
      (mediaStyle === 'Animation' && !animation) ||
      (assetTypename &&
        assetTypename === 'ContentfulWrapperVideo' &&
        mediaStyle != 'Video' &&
        mediaStyle != 'Animation')
    ) {
      return null
    }

    if (props.data.topPadding) topPadding = props.data.topPadding
    if (props.data.bottomPadding) bottomPadding = props.data.bottomPadding
    if (props.data.headline) headline = props.data.headline
    if (props.data.body) body = props.data.body
    if (props.data.assetTextCta) cta = props.data.assetTextCta

    const mask = type(mediaStyle)

    const enlargable = mediaStyle === 'Enlargable' || mediaStyle === 'Animation'

    return (
      <styles.MediaTextContainer
        background={theme}
        alignment={imageAlignment}
        enableClickToEnlarge={enlargable}
        topPadding={topPadding}
        bottomPadding={bottomPadding}
        mediaStyle={mediaStyle}
        data-cy="MediaText"
      >
        <GlobalContainer
          className="MediaText__Outer-Container"
          maxWidth={false}
        >
          <div
            className={`MediaText__Inner-Container ${
              mediaAsset ? 'MediaAsset' : ''
            }`}
          >
            {enlargable && !videoId && (
              <DottedModal open={open} handleClose={handleClose} type="image">
                {animation && animationMP4 ? (
                  <video src={asset} playsInline autoPlay muted loop />
                ) : (
                  <WrapperImage
                    src={ImgUtil.getResizedImageUrl(asset, '')}
                    //gifs don't animate with srcset attribute (tested on mac chrome m1)
                    {...(!animation
                      ? {
                          srcSet: ImgUtil.getSrcSet(asset),
                          sizes: '50vw',
                        }
                      : {})}
                    loading="lazy"
                    alt={assetAltText}
                    threshold={650}
                  />
                )}
              </DottedModal>
            )}
            <div className="MediaText__Image-Container" onClick={handleOpen}>
              <div className="MediaText__Mask-Wrapper">
                {mask === 'NONE' && (
                  <div className="MediaText__Mask--None">
                    {enlargable && (
                      <svg
                        fill="none"
                        height="16"
                        viewBox="0 0 16 16"
                        width="16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="search-icon"
                          clipRule="evenodd"
                          d="m10.7414 10.464 3.8023 3.8022c.262.2613.262.6861 0 .9474-.2613.262-.6861.262-.9474 0l-3.80224-3.8022c-1.0318.8254-2.34031 1.3185-3.76406 1.3185-3.33057 0-6.03-2.6994-6.03-6.02998 0-3.33057 2.69943-6.029998 6.03-6.029998s6.03 2.699428 6.03 6.029998c0 1.42375-.4931 2.73226-1.3186 3.76408zm-5.3814-4.43408v-1.675c0-.36984.30016-.67.67-.67s.67.30016.67.67v1.675h1.675c.36984 0 .67.30016.67.67s-.30016.67-.67.67h-1.675v1.675c0 .36984-.30016.67-.67.67s-.67-.30016-.67-.67v-1.675h-1.675c-.36984 0-.67-.30016-.67-.67s.30016-.67.67-.67zm.67 5.35998c2.59022 0 4.69-2.09976 4.69-4.68998s-2.09978-4.69-4.69-4.69-4.69 2.09978-4.69 4.69 2.09978 4.68998 4.69 4.68998z"
                          fillRule="evenodd"
                        />
                      </svg>
                    )}
                    {animation && animationMP4 ? (
                      <video src={asset} playsInline autoPlay muted loop />
                    ) : (
                      <WrapperImage
                        alt={assetAltText}
                        src={ImgUtil.getResizedImageUrl(asset, '')}
                        loading="lazy"
                        width={690}
                        height={690}
                        threshold={650}
                      />
                    )}
                  </div>
                )}
                {mask === 'VIDEO' && (
                  <div className="MediaText__Mask--Video">
                    <VideoPlayer
                      width={'100%'}
                      height="429"
                      videoId={videoId}
                      img={asset}
                      type={
                        videoId && props.data.asset.youtubeId
                          ? 'youtube'
                          : 'vimeo'
                      }
                      backgroundAltText={assetAltText}
                      backgroundImgParams={'width=690'}
                      start={start}
                    />
                  </div>
                )}
                {mask === '16X9' && (
                  <div className="MediaText__Mask--Image">
                    <WrapperImage
                      src={ImgUtil.getResizedImageUrl(asset, '')}
                      loading="lazy"
                      alt={assetAltText}
                      width={690}
                      height={690}
                      threshold={650}
                    />
                  </div>
                )}
              </div>
            </div>
            {!mediaAsset && (
              <div className="MediaText__Text-Container">
                {headline && (
                  <Typography type="header3" className="MediaText__Heading">
                    {formatInlineCode(headline)}
                  </Typography>
                )}
                {body && (
                  <Typography type="body" className="MediaText__Body">
                    {renderRichText(body, options)}
                  </Typography>
                )}
                {cta && (
                  <Button
                    icon={true}
                    href={
                      cta.internalLink
                        ? internalLinkHandler(cta.internalLink)
                        : cta.externalUrl
                    }
                    text={cta.text}
                    type="text-link"
                    size="small"
                    theme={theme === 'Dark' ? 'dark' : 'light'}
                  />
                )}
              </div>
            )}
          </div>
        </GlobalContainer>
      </styles.MediaTextContainer>
    )
  }

  return null
}

export default MediaText
